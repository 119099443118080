var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"mx-auto py-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_c('router-link',{attrs:{"to":{ name: 'AssessmentCenter' }}},[_vm._v("Assessment center")]),_vm._v(" | "),_c('span',{staticClass:"title"},[_vm._v("Under Investigation Reports ")])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12","xl":"11"}},[_c('v-card',{staticClass:"tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10",attrs:{"elevation":"2"}},[_c('card-title',{attrs:{"icon":"mdi-book","is-image":false}},[_vm._v("Under Investigation Reports ")]),(_vm.isBookings || _vm.search)?_c('div',[_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('div',{staticClass:"tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('hr'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bookings,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.loadingTable,"page":_vm.pagination.pageNumber,"items-per-page":_vm.pagination.perPage,"server-items-length":_vm.pagination.totalItems,"footer-props":{
              itemsPerPageOptions: [10, 20, 30, 40, 50],
            }},on:{"update:search":function($event){_vm.search=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "pageNumber", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "perPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "perPage", $event)}},scopedSlots:_vm._u([{key:"item.accidentCategory",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":"warning","small":""}},[_vm._v(" "+_vm._s(item.status.replaceAll('_', ' '))+" ")])]}},{key:"item.biddingTemplate.make",fn:function(ref){
            var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.biddingTemplate.make)+" "+_vm._s(item.biddingTemplate.model)+" ")])]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatToHuman")(item.createdAt)))])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-flex-row"},[_c('router-link',{key:item.action,attrs:{"to":{
                    name: 'BidDetails',
                    params: { bookingId: item.id, fullReport: true },
                  }}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-eye")]),_vm._v(" View")],1)],1)]}}],null,true)})],1):_c('div',{staticClass:"\n            tw-py-4\n            md:tw-py-14\n            tw-flex tw-items-center tw-justify-center tw-flex-col\n          "},[_c('img',{staticClass:"tw-h-16 md:tw-h-28",attrs:{"src":require('@/assets/img/assesment-center/no-car.png'),"alt":"no car"}}),_c('p',{staticClass:"tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify"},[_vm._v(" There’s no under investigation reports here, please head to the "),_c('span',{staticClass:"c-blue-text tw-font-bold"},[_vm._v("previous module in the action panel")])]),_c('v-btn',{staticClass:"tw-w-full md:tw-w-1/3",attrs:{"to":{ name: 'AssessmentCenter' },"color":"warning"}},[_vm._v("Go To Action Panel")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }